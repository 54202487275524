import React, {useEffect} from 'react';
import Navigation_mobile from '../../components/mobile/Navigation_mobile';
import { HashLink as Link } from 'react-router-hash-link';
import {InstagramFilled,MailFilled,EyeFilled} from '@ant-design/icons';
import {Card, Image, FloatButton } from 'antd';
import Footer_mobile from '../../components/mobile/Footer_mobile';

import AOS from "aos";
import "aos/dist/aos.css";

const { Meta } = Card;

const Print_mobile = ({portfolio, selectedItem, setSelectedItem}) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        AOS.refreshHard();
      }, []);
    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

return(
   
        <div id="1" className='print_mobile'>

            <Navigation_mobile data={portfolio} selectedItem={selectedItem} setSelectedItem={setSelectedItem}/>

            <div className="logo_mobile">
            <Link style={{color:"#000"}} smooth to="/#home" onClick={ () => (handleItemClick("home"))} end="true">
                  
                <img  alt="Logo" src={"./logo_clara.jpeg"} />
                <h1>Clara MINISINI</h1>
                <h2>PHOTOGRAPHE</h2>
                    
            </Link>
                
            </div>


            <div className='print_container_mobile'>
                <div className='print_description_container'>
                    <h1 className='title_print'>Print</h1>
                    <div className="print_description_mobile">
                        
                        <h3>Tu as envie d’obtenir le tirage d’une de mes photographies ? ✨ </h3>
                        <br/>
                        
                        <br/>

                        <p>Toutes les informations sont ici :</p>
                        <br/>

                            <ul>
                                <li>Les tirages sont numérotés (/30) et signés, accompagnés d’un <span>certificat d’authenticité</span>.</li>
                            </ul>
                        <br/>
                        <br/>
                        <p class="dispo">Deux types de tirages sont <span>disponibles</span> : </p>
                        <br/>
                            <ul>
                                <li>1 : Tirage simple - <span>20x30cm</span> - Papier mat Ultra Smooth Hahnemühle 305g</li>
                                <br/>
                                <li>2 : Prêt à encadrer - <span>30x40cm</span> - Papier mat Ultra Smooth Hahnemühle 305g - Passe-partout, montage renforcé et mise sous Blister</li>

                            </ul>
                            <br/>
                            <br/>
                        <p class="fin">  
                            Si tu es intéressé et/ou si tu as des questions, tu peux me contacter via le <Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact"  > formulaire de contact</Link> ou sur <a  href="https://www.instagram.com/clara.mnsn/" target="true">Instagram @clara.mnsn </a> 🦋
                            <br/>
                            <br/>
                            Ci-dessous, tu trouveras les photos disponibles à l’impression :) 

                        </p>
                    </div>
                </div>

                <div className='pictures_print_mobile'>
                
                    <Card data-aos="zoom-out-up"
                        style={{
                        width: 270,
                        margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="Bleu de Bretagne"
                            src="../images/Print/BleudeBretagne.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/mer#0"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled/> </a>
                        ]}
                    >
                        <Meta
                        
                        title="Bleu de Bretagne"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer) avec certification d'authenticité."
                        />
                    </Card>
                
                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        
                        }}
                        cover={
                        <Image 
                            alt="Douceur"
                            src="../images/Print/Douceur.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/mer#0"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled/> </a>
                        ]}
                    >
                        <Meta
                        
                        title="See the sea"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>
                
                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="Dreamywave"
                            src="../images/Print/DreamyWave.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/mer#0"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled/> </a>
                        ]}
                    >
                        <Meta
                        
                        title="DreamyWave"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>

               
                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="Janie"
                            src="../images/Print/Janie.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/fleurs#1"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled/> </a>
                        ]}
                    >
                        <Meta
                        
                        title="Janie"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>

                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="Lilas"
                            src="../images/Print/Lilas.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/fleurs#1"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled/> </a>
                        ]}
                    >
                        <Meta
                        
                        title="Lilas"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>



                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="Luc"
                            src="../images/Print/Luc.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/mer#0"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled/> </a>
                        ]}
                    >
                        <Meta
                        
                        title="Luc"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>


                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="Merdor"
                            src="../images/Print/Merdor.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/mer#0"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled/> </a>
                        ]}
                    >
                        <Meta
                        
                        title="Mer d'or"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>


                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="Orchidée"
                            src="../images/Print/Orchidee.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/fleurs#1"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled/> </a>
                        ]}
                    >
                        <Meta
                        
                        title="Orchidée"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>



                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="Pivoines"
                            src="../images/Print/Pivoines.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/fleurs#1"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled /> </a>
                        ]}
                    >
                        <Meta
                        
                        title="Pivoines"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>

                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="Roses"
                            src="../images/Print/Roses.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/fleurs#1"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled /> </a>
                        ]}
                    >
                        <Meta
                        
                        title="Roses"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>
                
                    <Card data-aos="zoom-out-up"
                        style={{
                            width: 270,
                            margin: "2.5em 0 2.5em 0"
                        }}
                        cover={
                        <Image 
                            alt="SeetheSea"
                            src="../images/Print/SeetheSea.jpg"
                        />
                        }
                        actions={[
                            <div><Link smooth to={"/portfolio/mer#0"} ><EyeFilled  /> </Link> </div>,
                            <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" > <MailFilled/>  </Link> </div>,
                            <a href="https://www.instagram.com/clara.mnsn/" target="true" > <InstagramFilled/> </a>
                        ]}
                    >
                        <Meta
                        
                        title="See the sea"
                        description="Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité."
                        />
                    </Card>


                </div>
            </div>
            <FloatButton.BackTop style={{zIndex: 4}} duration={500}  visibilityHeight={600} />
            <Footer_mobile/>
        </div>
    );
};

export default Print_mobile;