import React, {useEffect} from 'react'
import Navigation from "../components/Navigation"
import {List,Image } from 'antd'
import {InstagramFilled,MailFilled,EyeFilled} from '@ant-design/icons';
import { FloatButton, message } from 'antd';
import Footer from '../components/Footer';

import { HashLink as Link } from 'react-router-hash-link';
import AOS from "aos";
import "aos/dist/aos.css";

const Print = ({portfolio, selectedItem, setSelectedItem}) => {

    const [messageApi, contextHolder] = message.useMessage();
    const clickdroit = (event) => {
        event.preventDefault();
        messageApi.open({
        type: 'info',
        content: "Contactes-moi directement via le formulaire, instagram ou bien claraminisini.pro@gmail.com si tu veux plus d'informations sur cette photo :)",
        className: 'custom-class',
        duration:4.0,
        style: {

            marginTop: '15vh',
            fontSize: "medium",
            paddingTop:"10px"
        },
        });
    };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

      const handleItemClick = (item) => {
        setSelectedItem(item);
    };
    return (
        <div id="1">
            <Navigation data={portfolio} selectedItem={selectedItem} setSelectedItem={setSelectedItem} logo="./logo.svg"/>
            <div className='print_container'>
                <h1 className='title_print'>Print</h1>
                <div className="print_description">
                    
                    <h3>Tu as envie d’obtenir le tirage d’une de mes photographies ? ✨ </h3>
                    <br/>
                    
                    <br/>

                    <p>Toutes les informations sont ici :</p>
                    <br/>

                        <ul>
                            <li>Les tirages que je propose sont  numérotés (/30) et signés. Ils sont également accompagnés d’un <span>certificat d’authenticité</span>.</li>
                        </ul>
                    <br/>
                    <br/>
                    <p class="dispo">Deux types de tirages sont <span>disponibles</span> : </p>
                    <br/>
                        <ul>
                            <li>1 : Tirage simple - <span>20x30cm</span> - Papier mat Ultra Smooth Hahnemühle 305g</li>
                            <li>2 : Prêt à encadrer - <span>30x40cm</span> - Papier mat Ultra Smooth Hahnemühle 305g - Passe-partout, montage renforcé et mise sous Blister</li>

                        </ul>
                        <br/>
                    <p class="fin">  
                        Si tu es intéressé et/ou si tu as des questions, tu peux me contacter via le <Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact"  > formulaire de contact</Link> ou sur <a  href="https://www.instagram.com/clara.mnsn/" target="true">Instagram @clara.mnsn </a> 🦋
                        <br/>
                        <br/>
                        Ci-dessous, tu trouveras les photos disponibles à l’impression :) 

                    </p>
                </div>


                <div className='print_picture'>

                <List
                    itemLayout="vertical"
                    size="large"
                    
                    dataSource={[{
                        title:"Bleu de Bretagne",
                        img:"../images/Print/BleudeBretagne.jpg",
                        description: "Catégorie Mer",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/mer#0"

                    },{
                        title:"Douceur",
                        img:"../images/Print/Douceur.jpg",
                        description: "Catégorie Mer",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/mer#0"

                    },{
                        title:"DreamyWave",
                        img:"../images/Print/DreamyWave.jpg",
                        description: "Catégorie Mer",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/mer#0"

                    },
                    {
                        title:"Janie",
                        img:"../images/Print/Janie.jpg",
                        description: "Catégorie Fleurs",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer) avec certification d'authenticité.",
                        link:"/portfolio/fleurs#1"

                    },{
                        title:"Lilas",
                        img:"../images/Print/Lilas.jpg",
                        description: "Catégorie Fleurs",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/fleurs#1"

                    },
                    
                    {
                        title:"Luc",
                        img:"../images/Print/Luc.jpg",
                        description: "Catégorie Mer",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/mer#0"

                    },
                    {
                        title:"Mer d'or",
                        img:"../images/Print/Merdor.jpg",
                        description: "Catégorie Mer",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/mer#0"

                    },
                    {
                        title:"Orchidée",
                        img:"../images/Print/Orchidee.jpg",
                        description: "Catégorie Fleurs",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/fleurs#1"

                    },
                    {
                        title:"Pivoines",
                        img:"../images/Print/Pivoines.jpg",
                        description: "Catégorie Fleurs",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/fleurs#1"

                    },
                    {
                        title:"Roses",
                        img:"../images/Print/Roses.jpg",
                        description: "Catégorie Fleurs",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/fleurs#1"

                    },
                    {
                        title:"See the Sea",
                        img:"../images/Print/SeetheSea.jpg",
                        description: "Catégorie Mer",
                        content: "Tirages 20x30cm ou 30x40cm (prêt à encadrer)  avec certification d'authenticité.",
                        link:"/portfolio/mer#0"

                    },




                    ]}
                    
                    renderItem={(item) => (
                       
                            <List.Item data-aos="zoom-out" data-aos-duration="500" data-aos-easing="ease-in-sine"
                            className='item_list'
                                key={item.title}
                                actions={[
                                    <div><Link smooth to={item.link} style={{"fontSize":"12px"}}><EyeFilled  /> <p className="none_label_print">Voir la catégorie</p> </Link> </div>,
                                    <div><Link onClick={ () => (handleItemClick("contact"))} smooth to="/#contact" style={{"fontSize":"12px"}}> <MailFilled/>  <p className="none_label_print">Contact</p></Link> </div>,
                                    <a href="https://www.instagram.com/clara.mnsn/" target="true" style={{"fontSize":"12px"}}> <InstagramFilled/> <p className="none_label_print">Instagram</p></a>
                                ]}
                                extra={
                                <div draggable="false" onContextMenu={clickdroit} >
                                    {contextHolder}
                                <Image  
                                    className='image_print'
                                    alt="logo"
                                    src={item.img}

                                
                                />
                                </div>
                                }
                            >
                                <List.Item.Meta
                                
                                title={<p style={{"fontSize":"25px"}}>{item.title}</p>}
                                description={<p style={{"fontSize":"large"}}>{item.description}</p>}
                                />
                                {item.content}
                            </List.Item>
                        


                        )
                   
                    }
                />


                </div>





            </div>
            
            <FloatButton.BackTop duration={500}  visibilityHeight={600} onClick={ () => setSelectedItem("home")}/>
            <Footer />
        </div>
    );
};

export default Print;