import React, {useState, useEffect} from 'react';
import {CloseOutlined, CheckCircleTwoTone,CloseCircleFilled,InstagramFilled} from '@ant-design/icons';
import emailjs from "@emailjs/browser";
import { Button, Form, Input, notification} from 'antd';

import ReactDOMServer from 'react-dom/server';

import AOS from "aos";
import "aos/dist/aos.css";
const Contact_mobile = ({setHidden}) => {

    const [api, contextHolder] = notification.useNotification();
    const openNotification = () => {
        
        api.open({
        message: 'Confirmation \u{1F30A}',
        description:
            'Merci pour ton message ! J\'y réponds avec plaisir dès que je peux \u{1F642}',
        placement:'top',
        icon: (
            <CheckCircleTwoTone twoToneColor='#52c41a'
            
            />
        ),
        duration: 6,
        className: "notif_mobile"
        });
    };

    const errorNotification = () => {
    
        api.open({
          message: 'Erreur :/',
          description:
            'Oups petite erreure .. Tu peux recommencer via le formulaire, instagram ou bien claraminisini.pro@gmail.com ',
          placement:'top',
          icon: (
            <CloseCircleFilled  twoToneColor='#f08989'
              
            />
          ),
          duration: 6,
          className: "notif_mobile"
        });
      };

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
      setIsOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsOpen(false);
    };

    const [form] = Form.useForm();
    

    const onFinish = (values) => {

        emailjs.sendForm('service_jg6h6ix', 'template_e7vdrzj', "#contact-form", "qexgJorFs99fWW1Cm")
      .then((result) => {
        
        openNotification(); 
        handleCloseModal();
          
          
      }, (error) => {
       
        errorNotification();
         

      });
    };
    const onReset = () => {
        
        form.resetFields();
    };

    const validateMessages = {
        required: 'Ton ${label} est requis !',
        types: {
          email: '${label} n\'est pas valide !'
        }
      };


      useEffect(() => {
        AOS.init();
        AOS.refresh();
        AOS.refreshHard();
      }, []);

  
    return (
        <div>
            <div data-aos="zoom-out" className='formualire_section_mobile'>
                <p>Une question ou tout autre chose à me partager ? Ce formulaire est là pour ça 🌸 ✨ </p>
                <button onClick={() => (handleOpenModal())} className='button_overlay'>Formulaire</button>

                <p>OU</p>

                <p className='contact_insta'>Tu peux aussi me contacter via mon instagram si tu le veux !</p>
        
                <a className='contact_insta' size="large" href="https://www.instagram.com/clara.mnsn/" target="true"> <InstagramFilled style={{ fontSize: '18px', color:'#b3a8ca'}}/> </a>

            </div>
            
            {contextHolder}
            {isOpen && (
                <div className={`modal-overlay ${isOpen ? "open" : "closed"}`}  >
                    <CloseOutlined onClick={handleCloseModal} style={{color:"#ffff"}} className="cross"/>
                <div className={`modal-content ${isOpen ? "open" : "closed"}`}>
                    <h2>Formulaire</h2>


                    <Form
                        
                        form={form}
                        name="contact-form"
                        onFinish={onFinish}
                        style={{
                            maxWidth: 600,
                        }}
                        id="contact-form"

                        validateMessages={validateMessages}
                        >
                            <Form.Item
                                name="user_name"
                                label="Nom"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                                
                            >
                                <Input name="user_name" />
                            </Form.Item>
                            <Form.Item
                                name="user_firstname"
                                label="Prénom"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Input />

                            
                            </Form.Item>
                            <Form.Item
                                name="user_email"
                                label="Email"
                                rules={[
                                {   
                                    type: 'email',
                                    required: true,
                                },
                                ]}
                            >
                                <Input  name="user_email"/>

                            
                            </Form.Item>


                            <Form.Item name="user_message" label="Message" rules={[
                                {   
                                    
                                    required: true,
                                },
                                ]}>
                                <Input.TextArea name="user_message" />
                            </Form.Item>


                            <Form.Item>
                                <div className="button_submit">
                                <Button  type="primary" htmlType='submit' size='small' >
                                        Envoyer
                                        {/* <input type="submit"   size="large" value="." className='input_' />  */}
                                    </Button>

                                    <Button   size='small' onClick={onReset} className="button_reset">
                                        Enlever les informations
                                    </Button>
                                </div>

                            
                                
                            </Form.Item>
                    </Form>
                    
                </div>
                </div>
            )}
    </div>
    );
};

const formHtml = ReactDOMServer.renderToStaticMarkup(<Contact_mobile />);

export default Contact_mobile;