import React from 'react';
import {LinkedinFilled} from '@ant-design/icons';
import { Tooltip } from 'antd';

const Footer = () => {
    return (
        <div className='container-footer'>
            <div className='container-footer-description'>
                <div className='description'>
                    <p>Photographies de Clara MINISINI.</p>
                    <p>Toutes reproductions ou modifications interdites.</p>
                    <p>2023. Réalisé par <Tooltip title={`MahanoraTetuanui`} color={"#b3a8ca"} placement="right"><a href='https://www.linkedin.com/in/mahanora-tetuanui' target="true"><LinkedinFilled /> </a> </Tooltip> </p>
                </div>
            </div>
            
            
        </div>
    );
};

export default Footer;