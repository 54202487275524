import React, {useState} from 'react';
import { elastic as Menu } from 'react-burger-menu';
import { HashLink as Link } from 'react-router-hash-link';
import {CaretRightOutlined} from '@ant-design/icons';


const Navigation_mobile = ({data, selectedItem, setSelectedItem}) => {

  
  
  const [activeMenuItem, setActiveMenuItem] = useState(null); // État pour stocker l'élément de menu actif

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem === activeMenuItem ? null : menuItem); // Si l'élément de menu est déjà actif, le désactive, sinon l'active
    
  };

  const handleItemClick = (item) => {
    
    setSelectedItem(item);
    
  };
    return (
        <div>
             <Menu >
                <Link smooth to="/#home" onClick={ () => (handleItemClick("home"))} >
                  
                  <p className={selectedItem === "home" ? 'selected_mobile' : "" }>Home</p>
                    
                </Link>
                
                <p
            className={`menu-item-button ${(activeMenuItem === 'p') || (selectedItem === "mer" || selectedItem === "fleurs" ||selectedItem === "argentique" ||selectedItem === "melimelo" ||selectedItem === "mp" || selectedItem === "humain" ) ? 'selected_mobile' : ''} `}
            onClick={() => {handleMenuItemClick('p');}}
                >
                  Portfolio <CaretRightOutlined rotate={activeMenuItem ==='p'? 90 : 0 }/>
                </p>
                {activeMenuItem === 'p' && (
                  <ul className="sub-menu">
                    <li className="sub-menu-item"   onClick={ () => (handleItemClick("mer"))}><Link className={selectedItem === "mer" ? 'selected_mobile' : "" } to={"/portfolio/"+data[0].link + "#" + data[0].id}> {data[0].title} </Link></li>

                    <li className="sub-menu-item" onClick={ () => (handleItemClick("fleurs"))}><Link className={selectedItem === "fleurs" ? 'selected_mobile' : "" } to={"/portfolio/"+data[1].link + "#" + data[1].id}> {data[1].title} </Link></li>

                    <li className="sub-menu-item" onClick={ () => (handleItemClick("argentique"))}><Link className={selectedItem === "argentique" ? 'selected_mobile' : "" } to={"/portfolio/"+data[2].link + "#" + data[2].id}> {data[2].title} </Link></li>

                    <li className="sub-menu-item" onClick={ () => (handleItemClick("melimelo"))}><Link className={selectedItem === "melimelo" ? 'selected_mobile' : "" } to={"/portfolio/"+data[3].link + "#" + data[3].id}> {data[3].title} </Link></li>

                    <li className="sub-menu-item" onClick={ () => (handleItemClick("mp"))}><Link className={selectedItem === "mp" ? 'selected_mobile' : "" }  to={"/portfolio/"+data[4].link + "#" + data[4].id}> {data[4].title} </Link></li>

                    <li className="sub-menu-item" onClick={ () => (handleItemClick("humain"))}><Link className={selectedItem === "humain" ? 'selected_mobile' : "" } to={"/portfolio/"+data[5].link + "#" + data[5].id}> {data[5].title} </Link></li>
                  </ul>
                )}
                
                <Link onClick={ () => (handleItemClick("about"))} smooth to="/#aboutme" >
                  
                  <p className={selectedItem === "about" ? 'selected_mobile' : "" }>About me</p>
                    
                </Link>
                <Link smooth to="/print#1" onClick={ () => (handleItemClick("print"))} >
                  
                  <p className={selectedItem === "print" ? 'selected_mobile' : "" }>Print</p>
                    
                </Link>
                <Link smooth to="/#contact" onClick={ () => (handleItemClick("contact"))} >
                  
                  <p className={selectedItem === "contact" ? 'selected_mobile' : "" }>Contact</p>
                    
                </Link>
                
              </Menu>
            
        </div>
    );
};

export default Navigation_mobile;