import React, {useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PortfolioCategories from './pages/PortfolioCategories';
import Portfolio_data from './data/portfolio.json';
import Print from './pages/Print';
import { Button, Result } from 'antd';
import { HashLink as Link } from 'react-router-hash-link';
import { useMediaQuery } from 'react-responsive';


import Navigation from './components/Navigation';
import Home_mobile from './pages/mobile/Home_mobile';
import PortfolioCategories_mobile from './pages/mobile/PortfolioCategories_mobile';
import Print_mobile from './pages/mobile/Print_mobile';

const App = () => {


  const handleItemClick = (item) => {
    setSelectedItem(item);
};
  const [selectedItem, setSelectedItem] = useState("home");
  const [hidden, setHidden] = useState(false);

  

  const isDesktop = useMediaQuery({
    query: '(min-width: 983px)'
  });

  

  const Desktop = () => {
    
    return(

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home portfolio = {Portfolio_data} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}/>
          
          <Route path="/portfolio/mer" element={<PortfolioCategories portfolio = {Portfolio_data[0]} data={Portfolio_data} selectedItem={"portfolio"} setSelectedItem={setSelectedItem} selectPort={"1"}  />}/>
          <Route path="/portfolio/fleurs" element={<PortfolioCategories portfolio = {Portfolio_data[1]} data={Portfolio_data} selectedItem={"portfolio"} setSelectedItem={setSelectedItem} selectPort={"2"} />}/>
          <Route path="/portfolio/argentique" element={<PortfolioCategories portfolio = {Portfolio_data[2]} data={Portfolio_data} selectedItem={"portfolio"} setSelectedItem={setSelectedItem} selectPort={'3'} />}/>
          <Route path="/portfolio/meli_melo" element={<PortfolioCategories portfolio = {Portfolio_data[3]} data={Portfolio_data} selectedItem={"portfolio"} setSelectedItem={setSelectedItem} selectPort={'4'} />}/>
          <Route path="/portfolio/matieres_premieres" element={<PortfolioCategories portfolio = {Portfolio_data[4]} data={Portfolio_data} selectedItem={"portfolio"} setSelectedItem={setSelectedItem} selectPort={'5'} />}/>
          <Route path="/portfolio/humain_dans_la_ville" element={<PortfolioCategories portfolio = {Portfolio_data[5]} data={Portfolio_data} selectedItem={"portfolio"} setSelectedItem={setSelectedItem} selectPort={'6'} />}/>

          <Route path="/print" element={<Print portfolio = {Portfolio_data} selectedItem={"print"} setSelectedItem={setSelectedItem}/>}/>
          <Route path="*" element={<div> <Navigation data = {Portfolio_data} selectedItem={selectedItem} setSelectedItem={setSelectedItem} logo = "./logo.svg"/> <Result
          style={{paddingTop : "20em"}}
          icon={<img alt="logo" src={"./logo.svg"}/>}
      status="404"
      title="404"
      subTitle="Oups tu as cherchée une page qui n'existe pas ! Ce n'est pas grave, tu as toujours le menu ci-dessus ou le bouton juste en bas :) "
      extra={<Link smooth to="/"><Button type="primary" onClick={ () => (handleItemClick("home"))}>Page d'accueil</Button></Link>}
      /> </div>}/>
        </Routes>
      </BrowserRouter>

    )
    
  }



  const Mobile = () => {
     return(
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home_mobile portfolio = {Portfolio_data} hidden = {hidden} setHidden = {setHidden} selectedItem={selectedItem} setSelectedItem={setSelectedItem}/>}/>
          
          <Route path="/portfolio/mer" element={  <PortfolioCategories_mobile portfolio = {Portfolio_data[0]} data={Portfolio_data} hidden = {hidden} setHidden = {setHidden} selectedItem={"mer"} setSelectedItem={setSelectedItem}  />}/>

          <Route path="/portfolio/fleurs" element={<PortfolioCategories_mobile portfolio = {Portfolio_data[1]} data={Portfolio_data} hidden = {hidden} setHidden = {setHidden} selectedItem={"fleurs"} setSelectedItem={setSelectedItem}  />}/>

          <Route path="/portfolio/argentique" element={<PortfolioCategories_mobile portfolio = {Portfolio_data[2]} data={Portfolio_data} hidden = {hidden} setHidden = {setHidden}  selectedItem={"argentique"} setSelectedItem={setSelectedItem} />}/>

          <Route path="/portfolio/meli_melo" element={<PortfolioCategories_mobile portfolio = {Portfolio_data[3]} data={Portfolio_data}  hidden = {hidden} setHidden = {setHidden} selectedItem={"melimelo"} setSelectedItem={setSelectedItem} />}/>

          <Route path="/portfolio/matieres_premieres" element={<PortfolioCategories_mobile portfolio = {Portfolio_data[4]} data={Portfolio_data} hidden = {hidden} setHidden = {setHidden} selectedItem={"mp"} setSelectedItem={setSelectedItem}  />}/>

          <Route path="/print" element={<Print_mobile portfolio = {Portfolio_data} selectedItem={"print"} setSelectedItem={setSelectedItem}/>}/>

          <Route path="/portfolio/humain_dans_la_ville" element={<PortfolioCategories_mobile portfolio = {Portfolio_data[5]} data={Portfolio_data} hidden = {hidden} setHidden = {setHidden} selectedItem={"humain"} setSelectedItem={setSelectedItem}  />}/>
          
        </Routes>
      </BrowserRouter>
     )
  }

  return isDesktop ? <Desktop /> : <Mobile />;
};

export default App;