import React, {useEffect} from 'react';
import Navigation_mobile from '../../components/mobile/Navigation_mobile';
import Categorie_card_mobile from '../../components/Categorie_card_mobile';
import Contact_mobile from '../../components/mobile/Contact_mobile';
import Footer_mobile from '../../components/mobile/Footer_mobile';
import { FloatButton } from 'antd';
import { HashLink as Link } from 'react-router-hash-link';

import AOS from "aos";
import "aos/dist/aos.css";

const Home_mobile = ({portfolio, hidden, setHidden, selectedItem, setSelectedItem}) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        AOS.refreshHard();
      }, []);


      const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    

    
    return (
        <div className="home_mobile" >


            <Navigation_mobile data={portfolio} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />

            <div className="logo_mobile">
            <Link style={{color:"#000"}} smooth to="/#home" onClick={ () => (handleItemClick("home"))} end="true">
            
                <img alt="Logo" src={"./logo_clara.jpeg"} />
                <h1>Clara MINISINI</h1>
                <h2>PHOTOGRAPHE</h2>
                    
            </Link>
                
            </div>

            <div className='img_accueil_mobile'>
                <img  src="./images/Accueil/_MG_3371_v2.jpg" alt="Fond de la page d'accueil" />
            </div>

            <div >

                <h2 className='title_mobile'>Portfolio</h2>
                
                <Categorie_card_mobile anime={"fade-right"} data = {portfolio[0]} setHidden={setHidden} />
                <Categorie_card_mobile anime={"fade-left"} data = {portfolio[1]} setHidden={setHidden} />
                <Categorie_card_mobile anime={"fade-right"} data = {portfolio[2]} setHidden={setHidden} />
                <Categorie_card_mobile anime={"fade-left"} data = {portfolio[3]} setHidden={setHidden} />
                <Categorie_card_mobile anime={"fade-right"} data = {portfolio[4]} setHidden={setHidden} />
                <Categorie_card_mobile anime={"fade-left"} data = {portfolio[5]} setHidden={setHidden} />

            </div>

            <div id="aboutme" className="aboutme_mobile_container">
                <h2 data-aos="zoom-out" className='title_mobile'>About me</h2>
                <div className='aboutme_mobile'>
                    <img  data-aos="zoom-out" alt="profile_picture" src='./images/Home/About-me/photoaboutme.jpg'/>

                    <div data-aos="zoom-out" className='aboutme_section_mobile'>
                        <p>
                        Hello, je m’appelle Clara Minisini, j’ai 23 ans et je viens de Luc-sur-Mer en Normandie. 🌊
                        <br/>
                        <br/>
                        Je pratique la photographie depuis cinq ans maintenant. Cela fut une révélation pour moi, à tel point que j’ai décidé de suivre un BTS Photographie que j’ai obtenu en juin 2021
                        <br/>
                        <br/>
                        Si la photographie est devenue si importante dans ma vie, c’est qu’elle constitue pour moi un véritable moyen d’expression. 
                        <br/>
                        <br/>
                        En effet, je suis hypersensible et je ressens tout de manière très intense. Une jolie fleur, une douce lumière, le bruit du vent ou même une simple vague suffisent à m’émouvoir. A contrario, trop de bruit, de lumière, de mouvement ou encore d’interaction sociale me fatigue énormément. 
                        <br/>
                        <br/>
                        J’ai longtemps considéré toutes ces émotions comme un poids, une différence qui m’éloignait des autres.  
                        <br/>
                        <br/>
                        Mais là où la photographie a changé ma vie, c’est qu’elle me permet de faire de mon hypersensibilité une force. Grâce à elle, je peux modeler le réel pour montrer ma vision du monde et ainsi exprimer et transmettre ce que je ressens.
                        <br/>
                        <br/>
                        En regardant mes photos, vous regardez donc mon âme et les milliers d’émotions que j’abrite. Je vous souhaite la bienvenue dans mon univers fait de douceur, de sensibilité et de poésie. ✨🪐
                        </p>
                    </div>
                </div>
            </div>


            <div id="contact">
                <h2 data-aos="zoom-out" className='title_mobile' >Contact</h2>
               <Contact_mobile  setHidden={setHidden}/>
            </div>

            <FloatButton.BackTop style={{zIndex: 4}} duration={500}  visibilityHeight={600} />
            <Footer_mobile />
            
        </div>
    );
};

export default Home_mobile;