import React, {useEffect,useState} from 'react';
import Navigation from '../components/Navigation';
import { FloatButton , Image, message} from 'antd';

import Footer from '../components/Footer';





import AOS from "aos";
import "aos/dist/aos.css";

const Portfolio_Categories = ({portfolio, data, selectedItem, setSelectedItem, selectPort}) => {
     
    const [visible, setVisible] = useState(false);
    const[index, setIndex] = useState(0);

    const [messageApi, contextHolder] = message.useMessage();
    const clickdroit = (event) => {
        event.preventDefault();
        messageApi.open({
        type: 'info',
        content: "Contactes-moi directement via le formulaire, instagram ou bien claraminisini.pro@gmail.com si tu veux plus d'informations sur cette photo :)",
        className: 'custom-class',
        duration:4.0,
        style: {

            marginTop: '15vh',
            fontSize: "medium",
            paddingTop:"10px"
        },
        });
    };


    const onChange = (visible, index)=> {
        setVisible(visible);
        setIndex(index);
    } 
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        AOS.refreshHard();
      }, []);
    return (
        <div id={portfolio.id +""}>
            <Navigation data={data} logo = "../logo.svg" selectedItem={selectedItem} setSelectedItem={setSelectedItem} selectPort={selectPort}/>
            <div className='container-portfolio-categorie'>
                <h1 className='container-portfolio-categorie-titleSection'>Portfolio</h1>
                

                <div className='container-portfolio-categorie-description'>
                
                    <div draggable="false" onContextMenu={clickdroit} className='picture-description-container'>
                    
                        <Image  alt={portfolio.title} src={"." + portfolio.picture_description} className='picture-description' />
                        {contextHolder}
                    </div>
                    <div className='container-description'>
                        <div className='container-description-bloc'>

                            <h2>{portfolio.title}</h2>
                            
                            <p>
                            {portfolio.description}
                            </p>
                        </div>
                    </div>
                    
                </div>

                <div className='picture-containers'>

                
                    <div className='pictures-containers-left'>

                        {
                            portfolio.pictures.map((picture,index) =>{
                                
                                if(index%2 === 0  && picture !== "./images/Portfolio/Argentique/argentique_20.jpg" && picture !== "./images/Portfolio/Argentique/argentique_23.jpg"&& picture !== "./images/Portfolio/Argentique/argentique_21.jpg" && picture !== "./images/Portfolio/Meli_melo/melimelo_17.jpg" && picture !== "./images/Portfolio/Matiere_premiere/mp_15.jpg" && picture !== "./images/Portfolio/Matiere_premiere/mp_19.jpg" && picture !== "./images/Portfolio/Meli_melo/melimelo_20.jpg" && picture !== "./images/Portfolio/Fleurs/fleurs_11.jpg" && picture !== "./images/Portfolio/Mer/mer_23.jpg"){
                                    return (
                                        <div key={index} className='picture-container' data-aos="zoom-in-right" data-aos-duration="1000" data-aos-easing="ease-in-sine"  >
                                            {contextHolder}
                                            <Image draggable="false" onContextMenu={clickdroit}  preview={{ visible: false}} src={"."+picture} alt={portfolio.title+index} className='picture' onClick={() => onChange(true,index ,picture)} />
                                        </div>

                                            
                                    );
                                }else{
                                    return null;
                                }
                        
                                
                            })
                        }

                        
                    </div>

                    <div className='pictures-containers-right' >
                    {
                            portfolio.pictures.map((picture,index) =>{
                                
                                if(index%2 !== 0  || picture === "./images/Portfolio/Argentique/argentique_20.jpg" || picture === "./images/Portfolio/Argentique/argentique_23.jpg" ||picture === "./images/Portfolio/Argentique/argentique_21.jpg" ||picture === "./images/Portfolio/Meli_melo/melimelo_17.jpg" || picture === "./images/Portfolio/Matiere_premiere/mp_15.jpg" || picture === "./images/Portfolio/Matiere_premiere/mp_19.jpg" || picture == "./images/Portfolio/Meli_melo/melimelo_20.jpg"|| picture == "./images/Portfolio/Fleurs/fleurs_11.jpg" || picture == "./images/Portfolio/Mer/mer_23.jpg"){


                                    
                                    return (
                                        <div key={index} className='picture-container' data-aos="zoom-in-left" data-aos-duration="1000" data-aos-easing="ease-in-sine" >
                                            {contextHolder}
                                            <Image draggable="false" onContextMenu={clickdroit} preview={{ visible: false,}}  src={"."+picture} alt={portfolio.title+""+ index} className='picture' onClick={() => onChange(true,index ,picture)} />
                                        </div>
                                    );
                                }else{
                                    return null;
                                }
                        
                        
                               
                            })
                    }  
                    </div>
                    {contextHolder}
                    <div draggable="false" onContextMenu={clickdroit} 
                        style={{
                        display: 'none',
                        }}
                        >
                        
                        <Image.PreviewGroup
                        
                        preview={{
                            visible,
                            onVisibleChange: (vis) => setVisible(vis),
                            current: index
                        }}
                        >
                        {
                            portfolio.pictures.map((picture,index) => (
                            
                            
                                <Image  
                                alt={portfolio.title + " pictures"} key={index} src={"."+picture} />
                            
                            ))

                        }
                        
                        </Image.PreviewGroup>
                    </div>
                
                </div>
            </div>

            <FloatButton.BackTop duration={500}  visibilityHeight={600} />

            <Footer />
        </div>
    );
};

export default Portfolio_Categories;