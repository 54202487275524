
import React, { useRef,useEffect} from "react";
import emailjs from "@emailjs/browser";

import AOS from "aos";
import "aos/dist/aos.css";
import {InstagramFilled,CheckCircleTwoTone} from '@ant-design/icons';
import { Button, Input, Popover, notification } from 'antd';


const Contact = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    AOS.refreshHard();
  }, []);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    
    api.open({
      message: 'Confirmation \u{1F30A}',
      description:
        'Merci pour ton message ! J\'y réponds avec plaisir dès que je peux \u{1F642}',
      placement:'top',
      icon: (
        <CheckCircleTwoTone twoToneColor='#52c41a'
          
        />
      ),
      duration: 6,
      className: "notif"
    });
  };

  const { TextArea } = Input;
  const formu = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_jg6h6ix', 'template_e7vdrzj', formu.current, "qexgJorFs99fWW1Cm")
      .then((result) => {
        formu.current.reset();
        openNotification(); 
          
      }, (error) => {
         

      });
  };
  return (
    <div className="contactSection" data-aos="fade-up"
    data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom">
        <div className="form-container">
        <p data-aos="zoom-in-up"
    data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom">Une question ou tout autre chose à me partager ? Ce formulaire est là pour ça 🌸 ✨ 
        </p>
        <form  data-aos="zoom-in-up"
      data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom" 
      autoComplete="off" ref={formu}  onSubmit={sendEmail} >
        
        
          <label>Nom* </label>
            <Input style={{
                  width: '100%',
                }} type="text" name="user_name" required placeholder="Ton nom.." size="large" className="simple-input" allowClear />

                
        
          <label >Prénom* </label>
          <Input  type="text" name="user_firstname" required  placeholder="Ton prénom.." size="large" allowClear className="simple-input"/>



          <label >Email* </label>
          <Input type="email" name="user_email" required  placeholder="Ton email.." size="large" className="simple-input" allowClear />



          <label >Message* </label>
          <TextArea name="user_message" required placeholder="Ton message.." allowClear/>

          
          

          <div className="button-bloc" >   
          {contextHolder}
          <Popover content={ <p> Ne t'inquiète pas, toutes les informations qui te concernent et que tu as saisies ne seront pas partagées 🌸 </p>} title="Petite information ✨" placement="top" color={"#ffffb6"}>
                
              <input type="submit"   size="large" value="Envoyer" /> 
            </Popover>
          </div>

    
           
            


        </form>

        <p>Tu peux aussi me contacter via mon instagram si tu le veux !</p>
        
          <Button shape="circle" size="large" href="https://www.instagram.com/clara.mnsn/" target="true"> <InstagramFilled style={{ fontSize: '18px'}}/> </Button>

        
    </div>


    </div>
    
  );
};

export default Contact;
