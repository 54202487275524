import React, {useEffect} from 'react';

import AOS from "aos";
import "aos/dist/aos.css";


const AboutMe = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        AOS.refreshHard();
      }, []);
    function handleContextMenu(event) {
        event.preventDefault();
    }
    
    return (
        <div  className='aboutMeSection' data-aos="fade-up"
        data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom">
            <div className='aboutMe' data-aos="zoom-in-up" 
        data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom">
                <img onContextMenu={handleContextMenu} data-aos="zoom-in-up"
        data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom" alt="auto-portrait" src="./images/Home/About-me/photoaboutme.jpg" className='aboutMePicture'/>
                <div className='aboutMeText'data-aos="zoom-in-up"
        data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom">
                    <p>
                    Hello, je m’appelle Clara Minisini, j’ai 23 ans et je viens de Luc-sur-Mer en Normandie. 🌊
                    <br/>
                    <br/>
                    Je pratique la photographie depuis cinq ans maintenant. Cela fut une révélation pour moi, à tel point que j’ai décidé de suivre un BTS Photographie que j’ai obtenu en juin 2021
                    <br/>
                    <br/>
                    Si la photographie est devenue si importante dans ma vie, c’est qu’elle constitue pour moi un véritable moyen d’expression. 
                    <br/>
                    <br/>
                    En effet, je suis hypersensible et je ressens tout de manière très intense. Une jolie fleur, une douce lumière, le bruit du vent ou même une simple vague suffisent à m’émouvoir. A contrario, trop de bruit, de lumière, de mouvement ou encore d’interaction sociale me fatigue énormément. 
                    <br/>
                    <br/>
                    J’ai longtemps considéré toutes ces émotions comme un poids, une différence qui m’éloignait des autres.  
                    <br/>
                    <br/>
                    Mais là où la photographie a changé ma vie, c’est qu’elle me permet de faire de mon hypersensibilité une force. Grâce à elle, je peux modeler le réel pour montrer ma vision du monde et ainsi exprimer et transmettre ce que je ressens.
                    <br/>
                    <br/>
                    En regardant mes photos, vous regardez donc mon âme et les milliers d’émotions que j’abrite. Je vous souhaite la bienvenue dans mon univers fait de douceur, de sensibilité et de poésie. ✨🪐
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;