import React, {useEffect} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import {VerticalLeftOutlined } from '@ant-design/icons';

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
// import required modules
import { Scrollbar } from "swiper";

import AOS from "aos";
import "aos/dist/aos.css";
import { HashLink as Link } from 'react-router-hash-link';

const Categorie_card_mobile = ({anime, data}) => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        AOS.refreshHard();
      }, []);

      
    
    return (
        <div data-aos={anime}>

            
            <div>
                
                <Swiper
                    scrollbar={{
                    hide: true,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper"
                    // style={{backgroundColor: 'black'}}
                >
                    <SwiperSlide className='card'>
                        <div  className='card_intro'>
                            <Link  to={"/portfolio/"+ data.link + "#" + data.id} >
                                <h3 className='title_intro'>{data.title}</h3>
                            
                                <img  alt={data.title} src={data.picture_description} className='img_intro'/>
                            </Link>
                        </div>
                        
                    </SwiperSlide>


                    {   
                        data.pictures.map((picture,index) => {
                            
                            if( index < 5 ) {
                                return(
                                <SwiperSlide  key={picture + index + "card"}  className='card'> 
                                    <Link  to={"/portfolio/"+ data.link + "#" + data.id} >
                                        <img  key={picture + index + "image"} alt={data.title} src={picture}/> 
                                    </Link>                  
                                </SwiperSlide>
                        )}
                            

                        })
                    } 
                    <SwiperSlide  className='categorie_see_mobile'>
                        <Link  to={"/portfolio/"+ data.link + "#" + data.id} className='categorie_link_mobile'>
                            <h3 className='categorie_title_mobile'>Voir la catégorie <br/> <span>{data.title}</span></h3>
                            <VerticalLeftOutlined  className='categorie_icon_mobile' />

                        </Link> 
                    </SwiperSlide>
                </Swiper>
                
            </div>


        </div>
    );
};

export default Categorie_card_mobile;