
import React, {useEffect} from 'react';
import AboutMe from '../components/AboutMe';
import Contact from '../components/Contact';
import Navigation from '../components/Navigation';
import { FloatButton, message} from 'antd';

import "aos/dist/aos.css";
import CategoriesCard2 from '../components/CategoriesCard_2';
import AOS from "aos";
import Footer from '../components/Footer';




const Home = ({portfolio, selectedItem, setSelectedItem}) => {
    
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        AOS.refreshHard();
      }, []);



      
    return (
       
            <div className="home1" >
                <Navigation data = {portfolio} selectedItem={selectedItem} setSelectedItem={setSelectedItem} logo = "./logo.svg" />
                <div id="home"  className="fondHome">
                    <div>
                    
                        <img draggable="false" onContextMenu={(event) => event.preventDefault()} src="./images/Accueil/_MG_3371_v2.jpg" alt="Fond de la page d'accueil" />
                    </div>
                </div>
                <div id="portfolio" ></div>
                <h2 className='titleSection'>Portfolio</h2> 
                
                
                
                <div className='categorie-container'>
                    <CategoriesCard2  data = {portfolio[0]}  play={4500} bool={false} slideSide={'fade-left'} /> 
                    <CategoriesCard2  data = {portfolio[1]}   play={4600} bool={true} slideSide={'fade-right'}/> 
                    <CategoriesCard2  data = {portfolio[2]}  play={4700} bool={false} slideSide={'fade-left'}/> 
                    <CategoriesCard2   data = {portfolio[3]} play={4800} bool={true} slideSide={'fade-right'}/> 
                    <CategoriesCard2  data = {portfolio[4]}  play={4900} bool={false} slideSide={'fade-left'}/> 
                    <CategoriesCard2 data = {portfolio[5]}   play={5000} bool={true} slideSide={'fade-right'}/>
                </div>   
            
                <div id="aboutme"></div>
            <h2 className='titleSection' data-aos="zoom-out"
        data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom"> About me</h2>  
            
            

            <AboutMe />

            <div id="contact"></div>
            <h2 className='titleSection' data-aos="zoom-out"
        data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom">Contact</h2>

            <Contact/>

            <FloatButton.BackTop duration={500}  visibilityHeight={600} onClick={ () => setSelectedItem("home")}/>
                
                <Footer/>
            </div>
    );
};

export default Home;