import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/index.scss"




const root = ReactDOM.createRoot(document.getElementById('root'));
console.log("%c\u{26A0} \u{26A0} \u{26A0} ", "font-size: 100px;")
console.log("%cCoucou toi c'est le développeur ! ", 'font-size: 40px;')
console.log("%cTu es libre de regarder le code source mais juste pour te prévenir que toutes reproductions ou modifications des photographies de Clara Minisini sont interdites sans son consentement. Voilà c'est tout, cordialement, bisous.",'font-size: 30px;')
root.render(
  <React.StrictMode>
    
    <App  />
  </React.StrictMode>
);

