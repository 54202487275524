import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';



const Navigation = ({logo,data, selectedItem, setSelectedItem, selectPort}) => {
    
    const items = [
        {
          key: '1',
          label: (<Link to={"/portfolio/"+data[0].link + "#" + data[0].id}> {data[0].title} </Link>),
        },
        {
          key: '2',
          label: (<Link to={"/portfolio/"+data[1].link + "#" + data[1].id}> {data[1].title} </Link>),
        },
        {
          key: '3',
          label: (<Link to={"/portfolio/"+data[2].link + "#" + data[2].id}> {data[2].title} </Link>),
        },
        {
            key: '4',
            label: (<Link to={"/portfolio/"+data[3].link + "#" + data[3].id}> {data[3].title} </Link>),
          },
          {
            key: '5',
            label: (<Link to={"/portfolio/"+data[4].link + "#" + data[4].id}> {data[4].title} </Link>),
          },
          {
            key: '6',
            label: (<Link to={"/portfolio/"+data[5].link + "#" + data[5].id}> {data[5].title} </Link>),
          }
      ];

      

    const handleItemClick = (item) => {
          setSelectedItem(item);
        };
    return (
        <div id="nav" className="navigation">
            
            <div className='logo'>
                <Link to="/#home"  end="true">
                    <img alt="Logo" src={logo} />
                </Link>
                <h1>Clara MINISINI</h1>
            </div>
             
            
            
            <nav className='navBar'>

              
                <Link  to="/#home" className="navBar" onClick={ () => (handleItemClick("home"))} end="true">
                    
                  <p className={selectedItem === "home" ? 'selected' : "" } >Home</p>
                    
                </Link>
                
              
                <Dropdown className="navBar" 
                    menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: [selectPort]
                    }}
                    onClick={ () => (handleItemClick("portfolio"))}
                >
                    <Link   smooth to="/#portfolio" className="navBar" onClick={ () => (handleItemClick("portfolio"))}  end="true">
                        <p  className={`portfolio ${selectedItem === "portfolio" ? 'selected' : "" } `}>Portfolio <DownOutlined style={{ fontSize: '15px' }} className={selectedItem === "portfolio" ? 'selected' : "" }/> </p> 
                        
                    </Link>

                </Dropdown>
                
               
                    
               <Link  smooth to="/#aboutme" className="navBar" onClick={ () => (handleItemClick("aboutme"))}  end="true">
                    <p  className={selectedItem === "aboutme" ? 'selected' : "" } >About me</p>
                </Link>

                <Link  to="/print#1" className="navBar" onClick={ () => (handleItemClick("print"))}  end="true">
                <p className={selectedItem === "print" ? 'selected' : "" } >Print</p>
                </Link>
                    
                
                <Link  id="part-3" smooth to='/#contact' className="navBar" onClick={ () => (handleItemClick("contact"))}  end="true">
                <p className={selectedItem === "contact" ? 'selected' : "" } >Contact</p>
                </Link>


                
            </nav>                
            
        </div>
    );
};

export default Navigation;