import React from 'react';
import {LinkedinFilled} from '@ant-design/icons';

const Footer_mobile = () => {
    return (
        <div>
            <div className='container-footer_mobile'>
            <div className='container-footer-description_mobile'>
                <div className='description_mobile'>
                    <p>Photographies de Clara MINISINI.</p>
                    <p>Toutes reproductions ou modifications interdites.</p>
                    <p>2023. Réalisé par <a href='https://www.linkedin.com/in/mahanora-tetuanui' target="true"><LinkedinFilled /> </a></p>
                </div>
            </div>

            </div>
            
        </div>
    );
};

export default Footer_mobile;