

import AOS from "aos";
import "aos/dist/aos.css";

import React, {useEffect } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Tooltip,message } from 'antd';

// import required modules
import { Autoplay, Scrollbar } from "swiper";

import { HashLink as Link } from 'react-router-hash-link';

const CategoriesCard_2 = ({data, play, slideSide}) => {

    const [messageApi, contextHolder] = message.useMessage();
    const clickdroit = (event) => {
        event.preventDefault();
        messageApi.open({
        type: 'info',
        content: "Contactes-moi directement via le formulaire, instagram ou bien claraminisini.pro@gmail.com si tu veux plus d'informations sur cette photo :)",
        className: 'custom-class',
        duration:4.0,
        style: {
            marginTop: '15vh',
            fontSize: "medium"
        },
        });
    };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        AOS.refreshHard();
      }, []);
      
      

    return (
        <div>
            <h3 className={`subtitle_portfolio ${(data.title !== "Mer" && data.title !== "Fleurs") ? "subtitle_specific_title" : ""}`} data-aos={slideSide} data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-anchor-placement="top-bottom" > {data.title}</h3>
         <div className='categorie' >
            
         <Swiper
        slidesPerView={4}
        spaceBetween={30}
        autoplay={{
            delay: play,
            disableOnInteraction: false,
          }}
        modules={[Autoplay]}
        className="mySwiper"
        data-aos={slideSide}  data-aos-duration="800" data-aos-easing="ease-in-sine" >
        <SwiperSlide>
            <div className="card-wrapper " >
                <div className="card">
                    <div className="card-image">
                    <Link  to={"/portfolio/"+data.link + "#" + data.id} >
                        
                    <Tooltip title={`Voir la catégorie ${data.title}`} color={"#b3a8ca"}>
                        {contextHolder}
                        <img draggable="false" onContextMenu={clickdroit}  alt={data.title} src={data.picture_description} className='picture'/> 
                        </Tooltip>  
                    </Link>
                    </div>
                </div>

            </div>
        </SwiperSlide>

            {   
                data.pictures.map((picture,index) => (
                    <SwiperSlide> 
                        <div key={picture + index + "card-wrapper"} className="card-wrapper " >
                            <div key={picture + index + "card"} className="card">
                                <div key={picture + index + "card-image"} className="card-image">
                                <Link key={picture + index + "link"} to={"/portfolio/"+data.link + "#" + data.id} >
                                <Tooltip  key={picture + index + "Tooltip"} title={`Voir la catégorie ${data.title}`} color={"#b3a8ca"}>
                                {contextHolder}
                                    <img draggable="false" onContextMenu={clickdroit}  key={picture + index + "image"} alt={data.title} src={picture} className='picture'/> 
                                    </Tooltip>
                                </Link>
                                </div>
                            </div>

                        </div>
                    </SwiperSlide>

                ))
            } 

            </Swiper>
            
           
            
        </div>   
        </div>
    );
};

export default CategoriesCard_2;