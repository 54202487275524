import React, {useState,useEffect,useRef} from 'react';
import Footer_mobile from '../../components/mobile/Footer_mobile';
import { FloatButton } from 'antd';
// import {CloseCircleFilled  } from '@ant-design/icons';
import Stories  from 'react-insta-stories';
import Navigation_mobile from '../../components/mobile/Navigation_mobile';
import { HashLink as Link } from 'react-router-hash-link';
import AOS from "aos";
import "aos/dist/aos.css";

const PortfolioCategories_mobile = ({portfolio, setHidden, data, selectedItem, setSelectedItem}) => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        AOS.refreshHard();
      }, []);
    
      const modalRef = useRef(null);
      let startY = 0;
    
    const handleCloseModal = () => {
        
        setIsOpen(false);

    
    };

    const pictures = portfolio.pictures_mobile.map((picture) => {return "." + picture});

    const onChange = (index_)=> {
        
        setIndex(index_);
        setIsOpen(true);
    } 

   

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === "Backspace" || event.key === "Escape") {
            // Appuyer sur la touche "Retour" ou "Escape" pour fermer la modale
            handleCloseModal();
          }
        };
    
        // Ajouter un gestionnaire d'événements pour la touche "Retour" ou "Escape"
        document.addEventListener("keydown", handleKeyDown);
    
        // Nettoyer le gestionnaire d'événements lorsque le composant est démonté
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }, [handleCloseModal]);
    

      function fermerModale(event) {
        const modalElement = modalRef.current;
        const { clientY } = event.touches[0];
    
        if (startY > clientY) {
            setIsOpen(false);
        }
      }

    // Écouter l'événement "touchstart" sur la modale
    function handleTouchStart(event) {
        
        startY = event.touches[0].clientY;
    }

    // Écouter l'événement "touchmove" sur la modale
    function handleTouchMove(event) {
        event.preventDefault(); // Empêcher le défilement de la page pendant le glissement
        fermerModale(event);
    }

    // Écouter l'événement "touchend" sur la modale
    function handleTouchEnd() {
        startY = 0;
    }


    

    const[index, setIndex] = useState(0);


    const [isOpen, setIsOpen] = useState(false);

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };
    return (
        <div id={portfolio.id +""} >
            <Navigation_mobile data={data} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />

            <div className="logo_mobile">
                <Link style={{color:"#000"}} smooth to="/#home" onClick={ () => (handleItemClick("home"))} end="true">
                    
                    <img  alt="Logo" src={"../logo_clara.jpeg"} />
                    <h1>Clara MINISINI</h1>
                    <h2>PHOTOGRAPHE</h2>
                        
                </Link>
                
            </div>


            <div>
                <h2 className='title_mobile' style={{marginTop: "4em"}}>Portfolio</h2>

                <div className='section_description_mobile'>
                    <h3>{portfolio.title}</h3>
                    <p>{portfolio.description}</p>
                </div>

                <div className='picture-containers_mobile'>

                
                    <div className='pictures-containers-left_mobile'>


                        

                        {
                            portfolio.pictures_mobile.map((picture,index) =>{

                                if(index%2 === 0 || picture === "./images/Portfolio/Argentique/argentique_19.jpg" || picture === "./images/Portfolio/Argentique/argentique_23.jpg" || picture === "./images/Portfolio/Matiere_premiere/mp_15.jpg" || picture === "./images/Portfolio/Matiere_premiere/mp_19.jpg" || picture === "./images/Portfolio/Fleurs/fleurs_11.jpg" || picture === "./images/Portfolio/Mer/mer_23.jpg" || picture === "./images/Portfolio/Meli_melo/melimelo_22.jpg" ){
                                    return (
                                        <div data-aos="fade-up-right" key={index} className='picture-container_mobile'  >
                                           
                                            <img  onClick={() => onChange(index)}  src={"."+picture} alt={portfolio.title+index} className='picture_mobile'  />
                                        </div>

                                            
                                    );
                                }else{
                                    return null;
                                }
                        
                                
                            })
                        }

                        
                    </div>

                    <div className='pictures-containers-right_mobile' >
                    {
                            portfolio.pictures_mobile.map((picture,index) =>{

                                if((index%2 !== 0  && picture !== "./images/Portfolio/Argentique/argentique_19.jpg" && picture !== "./images/Portfolio/Argentique/argentique_23.jpg" && picture !== "./images/Portfolio/Matiere_premiere/mp_15.jpg" && picture !== "./images/Portfolio/Matiere_premiere/mp_19.jpg") && picture !== "./images/Portfolio/Mer/mer_22.jpg" && picture !== "./images/Portfolio/Fleurs/fleurs_11.jpg" && picture !== "./images/Portfolio/Mer/mer_23.jpg" && picture !== "./images/Portfolio/Meli_melo/melimelo_22.jpg" ){

                                    
                                    return (
                                        <div data-aos="fade-up-left" key={index} className='picture-container_mobile'  >
                                            
                                            <img onClick={() => onChange(index)}    src={"."+picture} alt={portfolio.title+""+ index} className='picture_mobile' />
                                        </div>
                                    );
                                }else{
                                    return null;
                                }
                        
                        
                               
                            })
                    }  
                    </div>

                    
                    
                </div>

            </div>

            {isOpen && ( 

               
                    <div ref={modalRef}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd} className={`stories `} >
                    
                    
                    {/* <CloseCircleFilled  onClick={handleCloseModal} style={{position:'absolute', top:"25px", right:"20px" , fontSize: "30px"}}/> */}
                    
                    
                    <Stories 
                        stories={pictures}
                        defaultInterval={5000}
                        width="100%"
                        height="100%"
                        currentIndex={index}
                        preventDefault={false}
                        onAllStoriesEnd={handleCloseModal}
                        keyboardNavigation={true}

                    
                    
                    />
                    
                </div>
            )} 


            <FloatButton.BackTop style={{zIndex: 4}} duration={500}  visibilityHeight={600} />
            <Footer_mobile/>
        </div>
    );
};

export default PortfolioCategories_mobile;